@use "../imports/base";

.reptile_gridContainer {
  display: grid;
  grid-auto-rows: min-content;
  grid-template-columns: minmax(0, 1fr) min-content;
  grid-template-areas:
  "header header"
  "content-header sidebar"
  "content content"
  "footer footer";
  row-gap: 4px;

  /*                                                                            */
  &--restructureBracketArea {
    grid-template-columns: minmax(0, 1fr);
    grid-template-areas:
  "header"
  "content-header"
  "sidebar"
  "content"
  "footer";
  }

  @include base.breakpoint-large {
    grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
    grid-template-areas:
    "header header"
    "content-header content-header"
    "sidebar content"
    "footer footer";
    column-gap: 4px;
  }

  .app & {
    margin-top: -4px; /*                                                                                              */
  }
}

.reptile_header {
  grid-area: header;

  @include base.breakpoint-large {
    /*                                                                    */
    .nav_navi-panel--level-1 > .nav_navi-panel__body {
      margin-bottom: 0;
    }
  }
}

.reptile_sidebar {
  grid-area: sidebar;
  overflow: hidden;
}

.reptile_contentHeader {
  grid-area: content-header;
  overflow: hidden;
}

.reptile_content {
  grid-area: content;
  overflow: hidden;
  margin-top: -4px; /*                                                                  */

  @include base.breakpoint-large {
    margin-top: 0;
  }
}

.reptile_footer {
  grid-area: footer;
  overflow: hidden;
}

.reptile_semanticHeadline--hidden {
  display: none;
}