@use "../imports/base";

.reptile_contentHeader {
  position: relative;
  background-color: base.$pl_color-white100;

  /*                        */
  @media (max-width: 767px) {
    .app &--hidden {
      display: none;
      height: 0;
    }
  }

  &__breadcrumb {
    padding: 10px 0;

    @include base.breakpoint-large {
      width: 75%;
      float: left;
    }
  }

  &__paging {
    display: none;
    padding: 2px base.$outer-grid-padding 2px 0;

    @include base.breakpoint-large {
      display: block;
      float: left;
      width: 25%;
    }
  }

  &__sponsoredHint {
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    max-height: 100px;
    overflow: hidden;

    &:empty {
      display: none;
    }
  }
}

.reptile_spellcheck {
  grid-area: content-header;
}

.oc-link-v1 {
  display: inline-block;
}
