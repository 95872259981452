@use "../imports/base";

.reptile_sidebar {
  background: base.$pl_color-white100;

  @include base.breakpoint-large {
    padding: 0 0 0 16px;
  }

  &--withoutLocalNavigation {
    .reptile_sidebar__localNavigation {
      display: none;
    }

    .pl_table-view .heureka_filterAccordion.pl_table-view__item:first-child {
      border-top-width: 0; /*                                                                                                      */
    }
  }

  /*                                                                                                                   */
  &:not(&--contentSite) &__localNavigation {
    display: none;

    @include base.breakpoint-large {
      display: block;
    }
  }

  &__filterSection {
    padding-top: 8px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @include base.breakpoint-large {
      display: block;
      padding: 0;
    }

    &:empty {
      padding: 0;
    }
  }

  & [hidden] {
    display: none;
  }

  /*                        */
  @media (max-width: 767px) {
    .app .reptile_contentHeader--hidden ~ & {
      height: 0; /*                                           */
    }
  }
}
